import React, {lazy} from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
const Layout = lazy(() => import('../modules/BrightSource/Training/TrainingLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp'));
// const UsageReport = lazy(() => import('../modules/reports/dashboards/UsageReport/UsageReport'));
const Training = lazy(() => import('../modules/support/Training'));

const ReturnButton = () => { 

  function returnToContent() {
    window.location.href = '/';
  }
  return (
    <>BrightSource Training 
  <div onClick={returnToContent} style={{display:'inline-flex', color: '#197c8b', verticalAlign:'middle', cursor: 'pointer'}}>
    <FuseSvgIcon style={{marginLeft: '10px', marginRight: '5px'}} size={22} >heroicons-outline:arrow-uturn-left</FuseSvgIcon>
    <span style={{fontSize: '12px', fontWeight: 400, verticalAlign: 'middle', lineHeight: '20px'}}>Return to Content</span> 
  </div>
  </>
  )
}

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'BrightSource',
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Training
            pageTitle={<div><ReturnButton /></div>}
            pageDescription="Guided walkthroughs to master BrightSource"
          />,
        },
        {
          path: 'Training',
          children: [
            {
              path: '',
              element: <ChatApp
                org={{key: "training", name: "BrightSource Training Resources"}}
                collection='training-1'
                pageTitle={<div><ReturnButton /></div>}
                pageDescription="Regulation and Care Facility Resources that you can converse with."
                chatHeader={<span>Complementary Regulations and Organization Demo</span>}
              />,
            },
            {
              path: 'Learn',
              element: <Training
              pageTitle={<div><ReturnButton /></div>}
                pageDescription="Guided walkthroughs to master BrightSource"
              />,
            },
            {
              path: 'Regulations',
              element: <Layout />,
              auth: ['admin', 'demo2'],
              children: [
                {
                  path: '',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo"}}
                    collection='demo-1'
                    level1='Regulations'
                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Regulations we offer to all care facilities for free to elevant Long Term Care"
                    chatHeader={<span>Regulations we offer to all care facilities for free to elevant Long Term Care</span>}
                  />,
                },
                {
                  path: 'SNF',
                  auth: ['admin', 'demo2'],
                  element: <ChatApp
                  org={{key: "demo", name: "BrightSource Demo"}}
                    collection='demo-1'
                    keywords="snf"
                    level1='Regulations'
                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Skilled Nursing Regulations we offer to all care facilities for free to elevant Long Term Care"
                    chatHeader={<span>Skilled Nursing Regulations we offer to all care facilities for free to elevant Long Term Care</span>}
                  />,
                },
                {
                  path: 'AL',
                  auth: ['admin', 'demo2'],
                    element: <ChatApp
                    org={{key: "demo", name: "BrightSource Demo"}}
                    collection='demo-1'
                    level1='Regulations'
                    keywords="al"
                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Assisted Living Regulations we offer to all care facilities for free to elevant Long Term Care"
                    chatHeader={<span>Assisted Living</span>}
                  />,
                }
              ],
            },

            {
              path: 'BrightSide',
              element: <Layout />,
              children: [
                {
                  path: '',
                  element: <ChatApp
                    org={{key: "training", name: "BrightSource Demo"}}
                    collection='training-1'
                    level1='Facility Documents'
                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Facility Documents representing what BrightSource can offer your organization"
                    chatHeader={<span>This could be your facility's document experience.</span>}
                  />,
                },
                {
                  path: 'Clinical',
                  element: <ChatApp
                    org={{key: "training", name: "BrightSource Demo"}}
                    collection='training-1'
                    level1='Facility Documents'
                    level2='Clinical Skills & Competencies'

                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Clinical Skills & Competencies Facility Documents representing what BrightSource can offer your organization"

                    chatHeader={<span>Instant access to your resources through conversations.</span>}
                  />,
                },
                {
                  path: 'Culinary',
                  element: <ChatApp
                    org={{key: "training", name: "BrightSource Demo"}}
                    collection='training-1'
                    level1='Facility Documents'
                    level2='Culinary Services'

                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Culinary Services Facility Documents representing what BrightSource can offer your organization"

                    chatHeader={<span>Instant access to your resources through conversations.</span>}
                  />,
                },
        
                {
                  path: 'Emergency',
                  element: <ChatApp
                    org={{key: "training", name: "BrightSource Demo"}}
                    collection='training-1'
                    level1='Facility Documents'
                    level2='Emergency Preparation'

                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Emergency Preparation Facility Documents representing what BrightSource can offer your organization"

                    chatHeader={<span>Empower your staff with AI.</span>}
                  />,
                },
        
                {
                  path: 'HR',
                  element: <ChatApp
                    org={{key: "training", name: "BrightSource Demo"}}
                    collection='training-1'
                    level1='Facility Documents'
                    level2='HR & Employment'

                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="HR & Employment Facility Documents representing what BrightSource can offer your organization"

                    chatHeader={<span>Get accurate up-to-date information as easily as chatting with a colleague.</span>}
                  />,
                },
        
                {
                  path: 'Tools',
                  element: <ChatApp
                    org={{key: "training", name: "BrightSource Demo"}}
                    collection='training-1'
                    level1='Facility Documents'
                    level2='Infection Control Toolkit'

                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Infection Control Facility Resources representing what BrightSource can offer your organization"

                    chatHeader={<span>BrightSource informs and reports on change, across the organization, the industry, and beyond.</span>}
                  />,
                },
        
                {
                  path: 'ProductDocs',
                  element: <ChatApp
                    org={{key: "training", name: "BrightSource Demo"}}
                    collection='training-1'
                    level1='Facility Documents'
                    level2='Manufacturer’s Instructions'

                    pageTitle={<div><ReturnButton /></div>}
                    pageDescription="Manufacturer’s Instructions representing what BrightSource can offer your organization"

                    chatHeader={<span>BrightSource informs and reports on change, across the organization, the industry, and beyond.</span>}
                  />,
                },
              ],
            },
          ],          
        }
      ]
    }
  ]
};


export default Routes;
