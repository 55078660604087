import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper"; 
import {
  Avatar,
  Box,
  Chip,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconButton, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField"; 
import MuiPhoneNumber from "mui-phone-number";
import { yupResolver } from "@hookform/resolvers/yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import * as yup from "yup";
import LanguageSwitcher from "app/theme-layouts/shared-components/LanguageSwitcher";
import VerifyPhoneModal from "./VerifyPhoneModal";
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useAuthContext from "src/app/AuthContext";


const schema = yup.object().shape({
  DisplayName: yup
    .string()
    .required("You must enter Your Name")
    .min(3, "Name should be of minimum 3 characters length")
    .matches(/^\w+\s+\w+$/, "Please enter your first and last name."),
  FirstName: yup
    .string()
    .required("You must enter Your First Name")
    .min(3, "Name should be of minimum 3 characters length"),
  LastName: yup
    .string()
    .required("You must enter Your Last Name")
    .min(3, "Name should be of minimum 3 characters length"),
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const defaultValues = {
  DisplayName: "",
  FirstName: "",
  LastName: "",
  Email: "",
  PhoneNumber: "",
};


const MyProfile = () => { 
  const [open, setOpen] = useState(false);
  const [userProfileInfo, setUserProfileInfo] = useState();
  const [preferredComMethod, setPreferredComMethod] = useState("");
  const [checked, setChecked] = useState(false);
  const [updateUserNotification, setUpdateUserNotification] = useState(false);
  const [preferredContactNotification, setPreferredContactNotification] = useState(false);
  const [receiveNotification, setReceiveNotification] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {currentUser, connectMicrosoftAccount, disconnectMicrosoftAccount } = useAuthContext();

  const [ isMicrosoftProviderConnected, isMicrosoftProviderConnectedSet ] = useState(false);


  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if(currentUser){
      let match = currentUser.providerData.filter(provider => provider.providerId == 'microsoft.com');
      if(match.length > 0 && isMicrosoftProviderConnected == false){
        isMicrosoftProviderConnectedSet(true);
      }
      else if(match.length == 0 && isMicrosoftProviderConnected == true) {
        isMicrosoftProviderConnectedSet(false);
      }
    }
    else {
      isMicrosoftProviderConnectedSet(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (userProfileInfo && userProfileInfo.PreferredComMethod) {
      setPreferredComMethod(userProfileInfo.PreferredComMethod);
    }
    if (userProfileInfo && userProfileInfo.ReceiveNotification !== undefined) {
      setChecked(userProfileInfo.ReceiveNotification);
    }
  }, [userProfileInfo]);

  const [alertstate, setAlertState] = useState({
    vertical: "bottom",
    horizontal: "right",
  });
  const { vertical, horizontal } = alertstate;

  const handleSwitchChange = async (event) => {
    
    /*
    setChecked(event.target.checked);
    let user = auth.currentUser;
    await updateDoc(
      doc(
        db,
        "Users",
        `${user.data.LastName}, ${user.data.FirstName} - ${user.email}`
      ),
      {
        ReceiveNotification: event.target.checked,
      }
    );

    setReceiveNotification(true);
    setTimeout(() => {
      setReceiveNotification(false);
    }, 3000);
    */
  };

  const handleChange = async (event) => {
    setPreferredComMethod(event.target.value);
    /*
    let user = auth.currentUser;
    await updateDoc(doc(db,"Users",`${user.data.LastName}, ${user.data.FirstName} - ${user.email}`),{PreferredComMethod: event.target.value});
    setPreferredContactNotification(true);
    setTimeout(() => {
      setPreferredContactNotification(false);
    }, 3000);
    */
  };

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  useEffect(() => {
    if (!userProfileInfo) return;    

    setValue("DisplayName", userProfileInfo?.DisplayName);    
    setValue("FirstName", userProfileInfo?.FirstName);    
    setValue("LastName", userProfileInfo?.LastName);    
    setValue("Email", userProfileInfo?.Email);
    setValue("EmailLC", userProfileInfo?.Email?.toLowerCase());
    setValue("PhoneNumber", userProfileInfo?.PhoneNumber);

  }, [userProfileInfo]);

  const handleClose = () => {
    setOpen(false);
    getDocument();
  };

  const handleEditClick = (card) => {
    setOpen(true);
  };

  const getAbv = (user) => {
    let displayName = user?.data?.DisplayName;
    if (displayName == null || displayName.trim() == "") return "";

    let lFirst = user.data.FirstName[0];
    let lLast = user.data.LastName[0];

    return `${lFirst}${lLast}`;
  };

  function toggleMicrosoftAccount() {

    //debugger;
    if(isMicrosoftProviderConnected){
      disconnectMicrosoftAccount();
      isMicrosoftProviderConnectedSet(false);
    }
    else {
      connectMicrosoftAccount();
      isMicrosoftProviderConnectedSet(true);
    }
  }

  const getRole = (user) => {
    let roles = user?.Roles;
    if (user == null || roles == null) return "Regulation User";

    let orgName = user?.data?.Organization;
    if (orgName != null) {
      orgName += " ";
    } else {
      orgName = "";
    }

    if (Array.isArray(roles)) {
      if (roles.includes("admin")) {
        return `${orgName}Administrator`;
      } else if (roles.includes("free")) {
        return `${orgName}Regulation User`;
      }
    }
    return "User";
  };
  const onSubmit = async (data) => {
    handleClose();

    return;
    /*
    let user = auth.currentUser;

    if (user) {
      let docId = `${user.data.LastName}, ${user.data.FirstName} - ${user.email}`;
      await updateDoc(doc(db, "Users", docId), {
        ...data,
      });
    }
    setUpdateUserNotification(true);
    setTimeout(() => {
      setUpdateUserNotification(false);
    }, 3000);
    */
  };
  useEffect(() => {
    if(userProfileInfo == null){      
      let user = currentUser.data;
      setUserProfileInfo(user);
    }
  }, [userProfileInfo]);

  const onSuccessHandler= ()=>{ 

  }

  return (
    <>
      <Box className="m-auto my-24 w-full sm:w-auto md:w-1/2">
        <Paper className="my-24 w-full rounded-md sm:shadow md:shadow-none rtl:border-l-1">
          <div className="bg-white shadow overflow-hidden sm:rounded-md relative">
          <div id="recaptcha-container"></div>
            <div className="flex flex-col items-center px-12 py-12 sm:px-6 text-center">
              {currentUser?.data?.PhotoURL ? (
                <Avatar
                  className="md:mx-4"
                  alt="user photo"
                  src={currentUser?.data?.PhotoURL}
                  sx={{ width: 65, height: 65 }}
                />
              ) : (
                <Avatar className="md:mx-4" sx={{ width: 65, height: 65 }}>
                  {getAbv(currentUser)}
                </Avatar>
              )}
              <p className="mt-5 text-lg text-gray-500">
                {getRole(userProfileInfo)}
              </p>
            </div>
            <Tooltip title="Edit" placement="bottom">
              <Button
                color="secondary"
                className="mt-4 absolute right-1 top-0"
                onClick={() => handleEditClick()}
              >
                <ModeEditOutlineIcon />
              </Button>
            </Tooltip>
            <Typography variant="h6" component="h2" className="mb-12 p-12">
              Contact Information
            </Typography>
            <VerifyPhoneModal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              onSuccessHandler={onSuccessHandler}
              defaultPhoneNumber={userProfileInfo?.PhoneNumber} 
            />
            <div className="border-t border-gray-200 pb-12">
              <dl>
                <div className="bg-gray-50 px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt className="text-lg font-medium text-gray-500">
                    Display Name
                  </dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0">
                    {userProfileInfo?.DisplayName}
                  </dd>
                </div>
                <div className="bg-white px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt className="text-lg font-medium text-gray-500">
                    First Name
                  </dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0">
                    {userProfileInfo?.FirstName}
                  </dd>
                </div>
                <div className="bg-gray-50 px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt className="text-lg font-medium text-gray-500">
                    Last Name
                  </dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0">
                    {userProfileInfo?.LastName}
                  </dd>
                </div>
                <div className="bg-white px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt className="text-lg font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0">
                    {userProfileInfo?.Email}
                  </dd>
                </div>
                <div className="bg-gray-50 px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt className="text-lg font-medium text-gray-500">
                    Phone Number
                  </dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0">
                    {userProfileInfo?.PhoneNumber ? (
                      <>
                        {userProfileInfo?.PhoneVerified ? (
                          <>
                            <div className="flex justify-between items-center">
                              {userProfileInfo?.PhoneNumber}
                              <Chip label="Verified" variant="outlined" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex justify-between items-center">
                              {userProfileInfo?.PhoneNumber}
                              <Button
                                className="ml-auto text-blue-500 rounded-md"
                                variant="outlined"
                                onClick={openModal}                             
                              >
                                Verify
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="flex justify-between items-center">
                        NA
                        <Button
                          className="text-blue-500 rounded-md"
                          variant="outlined"
                          onClick={openModal}
                        >
                          Add
                        </Button>
                      </div>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
            <hr />
            <div className="px-12 py-12">
              <Typography variant="h6" component="h2" className="py-7 mb-12">
                Communication Preferences
              </Typography>

              <div className="bg-white px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-lg font-medium text-gray-500">
                  Preferred Method
                </dt>
                <dd className="mt-1 text-lg text-gray-900 sm:mt-0">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Preferred Contact
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={preferredComMethod}
                      label="Preferred Contact"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Email"}>Email</MenuItem>
                      <MenuItem value={"Phone (text)"}>Phone (text)</MenuItem>
                      <MenuItem value={"Phone  (calls)"}>
                        Phone (calls)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </dd>
              </div>

              <div className="px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-lg font-medium text-gray-500">
                  Receive Notifications
                </dt>
                <dd className="mt-1 text-lg text-gray-900 sm:mt-0">
                  <FormControl
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <Switch
                      checked={checked}
                      onChange={handleSwitchChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </FormControl>
                </dd>
              </div>
              <hr />
              <div className="px-12 py-12 " style={{verticalAlign:'middle'}}>
                <Typography variant="h6" component="h2" className="py-7 mb-12">
                  Linked Accounts
                </Typography>

                <div className="bg-white px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt className="text-lg font-medium text-gray-500">
                    Microsoft Account
                  </dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0" style={{verticalAlign:'middle'}}>
                    <Button 
                        variant="contained"                                                 
                        size="small"
                        onClick={toggleMicrosoftAccount}
                      >
                        <FuseSvgIcon size={20} color="action" >
                          feather:microsoft
                        </FuseSvgIcon>
                        <Typography  color="text.primary" style={{paddingLeft: '8px', paddingBlockEndaddingRight: '8px'}} >
                         {isMicrosoftProviderConnected? 'Disconnect' : 'Connect'}
                        </Typography>
                    </Button>
                  </dd>
                </div>
              </div>
              <hr />
              <div className="px-12 py-12">
                <Typography variant="h6" component="h2" className="py-7 mb-12">
                  Application Settings
                </Typography>

                <div className="bg-white px-12 py-12 sm:grid sm:grid-cols-2 sm:gap-4">
                  <dt className="text-lg font-medium text-gray-500">
                    Preferred language
                  </dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0">
                    <LanguageSwitcher />
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="m-auto w-full"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 1,
            p: 4,
            width: "100%",
            maxWidth: 580,
          }}
        >
          <div className="absolute right-0 top-0 m-4">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="h6" component="h2" className="py-7">
            Edit Profile
          </Typography>
          <Box className="w-full sm:w-auto">
            <form className="mt-8 " onSubmit={handleSubmit(onSubmit)}>
              {currentUser?.data?.PhotoURL ? (
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  style={{
                    width: "100%",
                    padding: 0,
                    margin: "10px auto 30px",
                  }}
                >
                  <Avatar
                    className="my-2 mx-auto"
                    alt="user photo"
                    src={currentUser?.data?.PhotoURL}
                    sx={{ width: 55, height: 55 }}
                  />
                  <VisuallyHiddenInput type="file" />
                </Button>
              ) : (
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  style={{
                    width: "100%",
                    padding: 0,
                    margin: "10px auto 30px",
                  }}
                >
                  <Avatar
                    className="my-2 mx-auto"
                    sx={{ width: 60, height: 60 }}
                  >
                    {getAbv(currentUser)}
                  </Avatar>
                  <VisuallyHiddenInput type="file" />
                </Button>
              )}
              <Controller
                name="DisplayName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Display Name"
                    error={!!errors.DisplayName}
                    helperText={errors.DisplayName?.message}
                    className="mb-24"
                    fullWidth
                    required
                  />
                )}
              />
              <Controller
                name="FirstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="First Name"
                    error={!!errors.FirstName}
                    helperText={errors.FirstName?.message}
                    className="mb-24"
                    fullWidth
                    required
                  />
                )}
              />
              <Controller
                name="LastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Last Name"
                    error={!!errors.LastName}
                    helperText={errors.LastName?.message}
                    className="mb-24"
                    fullWidth
                    required
                  />
                )}
              />
              <Controller
                name="Email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Email"
                    className="mb-24"
                    fullWidth
                    disabled
                  />
                )}
              />
              <Controller
                name="PhoneNumber"
                control={control}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    variant="outlined"
                    label="Phone Number"
                    defaultCountry={"us"}
                    onlyCountries={["us"]}
                    disableDropdown
                    className="mb-24"
                    fullWidth
                  />
                )}
              />
              <div className="flex gap-5 justify-end mt-5">
                <Button
                  type="submit"
                  className="rounded my-5"
                  variant="contained"
                  color="secondary"
                >
                  Save Changes
                </Button>
                <Button
                  className="rounded my-5"
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={updateUserNotification}
        message="User has been upadated"
        key={"top" + "center"}
        autoHideDuration={2000}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={preferredContactNotification}
        message="Preferred Method has been upadated"
        key={"top" + "bottom"}
        autoHideDuration={2000}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={receiveNotification}
        message="Receive Notification has been upadated"
        key={"receiveNotification"}
        autoHideDuration={2000}
      />
    </>
  );
};

export default MyProfile;
