import i18next from 'i18next';
// import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import mx from './navigation-i18n/mx';
// import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
// i18next.addResourceBundle('tr', 'navigation', tr);
// i18next.addResourceBundle('ar', 'navigation', ar);
i18next.addResourceBundle('mx', 'navigation', mx);
i18next.addResourceBundle('ru', 'navigation', en);

  // BrightSource Free Regulations
const navigationConfig = [
   
  // AHI
  {
    comment: {/* AHI ------------- */},
    id: 'appsAhi',
    title: 'AHI Bright Sources',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'ahi', 'ahi_oncall'],
    children: [
      {
        comment: {/* AHI On-Call */},
        id: 'ahi-oncall',
        title: 'On-Call',
        type: 'collapse',
        checkPermission: true,
        auth: ['admin', 'ahi_oncall'],
        url: 'AHI-OnCall',
        children: [
          {
            id: 'AHI-OnCall-Aviva',
            title: 'Aviva',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/Aviva',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-DiamondWillow',
            title: 'Diamond Willow',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/DiamondWillow',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-EpiscopalHomes',
            title: 'Episcopal Homes',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/EpiscopalHomes',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-GiannaHomes',
            title: 'Gianna Homes',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/GiannaHomes',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-Greenbrier',
            title: 'Greenbrier',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/Greenbrier',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-PeacefulLiving',
            title: 'Peaceful Living',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/PeacefulLiving',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-PrairieSeniorCottages',
            title: 'Prairie Senior Cottages',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/PrairieSeniorCottages',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-SaintTherese',
            title: 'Saint Therese',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/SaintTherese',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-SholomCommunityAlliance',
            title: 'Sholom Community Alliance',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/SholomCommunityAlliance',
            auth: ['admin', 'ahi_oncall'],        
          },
          {
            id: 'AHI-OnCall-TheGenevaSuites',
            title: 'The Geneva Suites',
            type: 'item',        
            checkPermission: true,
            url: 'AHI-OnCall/TheGenevaSuites',
            auth: ['admin', 'ahi_oncall'],        
          }
        ]
      },

      {        
        comment: {/* CDC */},
        id: 'ahiCDC',
        title: 'CDC',
        type: 'collapse',        
        checkPermission: true,
        url: 'AHI/CDC/',
        auth: ['admin', 'ahi'],
      },
      {        
        comment: {/* Clinical Reference Material */},
        id: 'ahiCDC',
        title: 'Clinical Reference Material',
        type: 'collapse',        
        checkPermission: true,
        url: 'AHI/CRM/',
        auth: ['admin', 'ahi'],
      },
      {        
        comment: {/* Code of Federal Regulations (CFR)*/},
        id: 'ahiCFR',
        title: 'Code of Federal Regulations (CFR)',
        type: 'collapse',        
        checkPermission: true,
        url: 'AHI/CFR/',
        auth: ['admin', 'ahi'],
      },
      {
        comment: {/* Employment Documents */},
        id: 'ahiEmploymentDocs',
        title: 'Employment Documents',
        type: 'item',        
        checkPermission: true,
        url: 'AHI/Employment',
        auth: ['admin', 'ahi']
      },
      {
        comment: {/* Instructions & Guides */},
        id: 'ahiInstructions',
        title: 'Instructions & Guides',
        type: 'item',
        checkPermission: true,
        url: 'AHI/Instructions',
        auth: ['admin', 'ahi']
      },
      {
        comment: {/* MDS */},
        id: 'ahiMDS',
        title: 'MDS',
        type: 'item',
        checkPermission: true,
        url: 'AHI/MDS',
        auth: ['admin', 'ahi']
      },
      {        
        comment: {/* State Regulations - AL */},
        id: 'ahiALRegs',
        title: 'State Regulations - AL',
        type: 'collapse',        
        checkPermission: true,
        url: 'AHI/Regs/AL/',
        auth: ['admin', 'ahi'],
        children: [          
          {
            id: 'ahiALRegAz',
            title: 'Arizona',
            type: 'item',
            url: 'AHI/Regs/AL/AZ',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },
          {
            id: 'ahiALRegCO',
            title: 'Colorado',
            type: 'item',
            url: 'AHI/Regs/AL/CO',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },        
          {
            id: 'ahiALRegFL',
            title: 'Florida',
            type: 'item',
            url: 'AHI/Regs/AL/FL',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },  
          {
            id: 'ahiALRegMN',
            title: 'Minnesota',
            type: 'item',
            url: 'AHI/Regs/AL/MN',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },
          {
            id: 'ahiALRegTN',
            title: 'Tennessee',
            type: 'item',
            url: 'AHI/Regs/AL/TN',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },
          {
            id: 'ahiALRegWI',
            title: 'Wisconsin',
            type: 'item',
            url: 'AHI/Regs/AL/WI',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          }
        ]
      },
      {
        comment: {/* State Regulations - SNF */},
        id: 'ahiSNFRegs',
        title: 'SNF Regulations',
        type: 'collapse',        
        checkPermission: true,
        url: 'AHI/Regs/SNF/',
        auth: ['admin', 'ahi'],
        children: [
          {
            id: 'ahiSnfRegAz',
            title: 'Arizona',
            type: 'item',
            url: 'AHI/Regs/SNF/CO',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },{
            id: 'ahiSnfRegCO',
            title: 'Colorado',
            type: 'item',
            url: 'AHI/Regs/SNF/CO',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },
          {
            id: 'ahiSnfRegMN',
            title: 'Minnesota',
            type: 'item',
            url: 'AHI/Regs/SNF/MN',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },
          {
            id: 'ahiSnfRegTN',
            title: 'Tennessee',
            type: 'item',
            url: 'AHI/Regs/SNF/TN',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },
          {
            id: 'ahiSnfRegWI',
            title: 'Wisconsin - SNF',
            type: 'item',
            url: 'AHI/Regs/SNF/WI',
            auth: ['admin', 'ahi'],
            dense: true,
            end: true,
          },
        ]
      },
      {
        comment: {/* Policies and Procedures */},
        id: 'ahiPolicies',
        title: 'Policies and Procedures',
        type: 'item',
        checkPermission: true,
        url: 'AHI/Policies',
        auth: ['admin', 'ahi']
      },
      {
        comment: {/* Tools */},
        id: 'ahiTools',
        title: 'Tools & Templates',
        type: 'item',
        checkPermission: true,
        url: 'AHI/Tools',
        auth: ['admin', 'ahi']
      },
    ]
  },

  // The Geneva Suites 
  {
    id: 'TheGenevaSuites', 
    title: 'The Geneva Suites BrightSource',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'thegenevasuites'],
    children: [      
      {
        id: 'thegenevasuites-v1',
        title: 'The Geneva Suites',        
        type: 'collapse',
        autoExpand: true,
        dense: true,
        checkPermission: true,
        url: 'TheGenevaSuites',
        auth: ['admin', 'thegenevasuites'],
        children: [
          {
            id: 'thegenevasuites-v1-GeneralPolicies',
            title: 'General Policies',
            type: 'item',
            checkPermission: true,
            autoExpand: true,
            url: 'TheGenevaSuites/GeneralPolicies',
            auth: ['admin', 'thegenevasuites'],
          },
          {
            id: 'thegenevasuites-v1-InfectionControl',
            title: 'Infection Control',
            type: 'item',
            checkPermission: true,
            autoExpand: true,
            url: 'TheGenevaSuites/InfectionControl',
            auth: ['admin', 'thegenevasuites'],
          },
          {
            id: 'thegenevasuites-v1-MedicationsTreatment',
            title: 'Medications & Treatment',
            type: 'item',
            checkPermission: true,
            autoExpand: true,
            url: 'TheGenevaSuites/MedicationsTreatment',
            auth: ['admin', 'thegenevasuites'],
          },
          {
            id: 'thegenevasuites-v1-Nursing',
            title: 'Nursing',
            type: 'item',
            checkPermission: true,
            autoExpand: true,
            url: 'TheGenevaSuites/Nursing',
            auth: ['admin', 'thegenevasuites'],
          },
          {
            id: 'thegenevasuites-v1-Regulations',
            title: 'Regulations',
            type: 'item',
            checkPermission: true,
            autoExpand: true,
            url: 'TheGenevaSuites/Regulations',
            auth: ['admin', 'thegenevasuites'],
          }
        ]
      }
    ]
  }, 

  // Demo Navigation
  {
    id: 'demo', 
    title: 'Bright Source',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'demo2'],
    children: [
      {
        id: 'demo-brightsource',
        title: 'BrightSide Care Facility',        
        type: 'collapse',
        autoExpand: true,
        dense: true,
        checkPermission: true,
        auth: ['admin', 'demo2'],
        url: 'Demo',
        children: [
          {
            id: 'demo-regs',
            title: 'Regulations (FREE)',        
            type: 'collapse',
            autoExpand: true,
            checkPermission: true,
            url: 'Regulations',
            auth: ['admin', 'demo2'],
            children: [          
              {
                id: 'demo-regs-snf',
                dense: true,
                title: 'Skilled Nursing',
                type: 'item',
                url: 'Regulations/SNF',
                end: true,
              },
              {
                id: 'demo-regs-al',
                title: 'Assisted Living',
                type: 'item',
                url: 'Regulations/AL',
                end: true,
              },          
            ]
          }, 
          {
            id: 'free-demo-org',
            title: 'Facility Documents (DEMO)',
            type: 'collapse',
            checkPermission: true,
            autoExpand: true,
            url: 'BrightSide',
            auth: ['admin', 'demo2'],
            children: [          
              
              {
                id: 'free-demo-Clinical',
                dense: true,
                title: 'Clinical Skills & Competencies',
                type: 'item',
                url: 'BrightSide/Clinical',
                end: true,
              },
              {
                id: 'free-demo-Culinary',
                title: 'Culinary Services',
                type: 'item',
                url: 'BrightSide/Culinary',
                end: true,
              },
              {
                id: 'free-demo-Emergency',
                title: 'Emergency Preparation',
                type: 'item',
                url: 'BrightSide/Emergency',
                end: true,
              },
              {
                id: 'free-demo-hr',
                title: 'HR & Employment',
                type: 'item',
                url: 'BrightSide/HR',
                end: true,
              },
              {
                id: 'free-demo-tools',
                title: 'Infection Control Toolkit',
                type: 'item',
                url: 'BrightSide/Tools',
                end: true,
              },
              {
                id: 'free-demo-ManufacturerDocs',
                title: 'Manufacturer’s Instructions',
                type: 'item',
                url: 'BrightSide/ProductDocs',
                end: true,
              }
            ]
          },  
        ],
      }
    ]
    
  },

  // Sholom
  {
    id: 'sholomNav', 
    title: 'Bright Sources',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'sholom'],
    children: [
      
      {
        id: 'sholomBrightSource',
        title: 'Sholom Community Alliance',
        type: 'collapse',
        checkPermission: true,
        autoExpand: false,
        url: 'Sholom',
        auth: ['admin', 'sholom'],
        children: [
          {
            id: 'sholom-policies-procedures',
            title: 'Policies and Procedures',
            url: 'Sholom/PoliciesAndProcedures',
            type: 'item',          
            end: true,
          },

          {
            id: 'sholom-regs',
            title: 'Regulations',
            type: 'item',
            url: 'Sholom/Regulations',
            type: 'item',
            end: true,
          }
        ]
      },             
      {
        id: 'sholomSupport',
        title: 'Support',
        auth: ['sholom'],
        type: 'item',        
        url: 'support',
      }
    ]
  },

  {
    id: 'elysianNav', 
    title: 'Bright Sources',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'elysian', 'demo'],
    children: [
      {
        id: 'elysianBrightSource',
        title: 'Elysian Senior Homes',
        autoExpand: false,
        type: 'collapse',
        checkPermission: true,
        auth: ['admin', 'elysian', 'demo'],
        url: 'Elysian/',
        children: [
          
          {
            id: 'elysian-compentencies',
            dense: true,
            title: 'Competencies',
            type: 'item',
            url: 'Elysian/Competencies',
            end: true,
          },

          {
            id: 'elysian-emergencymanual',
            dense: true,
            title: 'Emergency Manual',
            type: 'item',
            url: 'Elysian/EmergencyManual',
            end: true,
          },

          {
            id: 'elysian-opsmanual',
            dense: true,
            title: 'Operations Manual',
            type: 'item',
            url: 'Elysian/OperationsManual',
            end: true,
          },

          {
            id: 'elysian-employmentdocs',
            dense: true,
            title: 'Employment Documents',
            type: 'item',
            url: 'Elysian/EmploymentDocuments',
            end: true,
          },

          {
            id: 'elysian-instructions',
            dense: true,
            title: 'Instructions',
            type: 'item',
            url: 'Elysian/Instructions',
            end: true,
          },
          {
            id: 'elysian-pp',
            dense: true,
            title: 'Policies & Procedures',
            type: 'item',
            url: 'Elysian/PoliciesAndProcedures',
            end: true,
          },
          {
            id: 'elysian-regs',
            dense: true,
            title: 'Regulations',
            type: 'item',
            url: 'Elysian/Regulations',
            end: true,
          },
          {
            id: 'elysian-pam',
            dense: true,
            title: 'Pricing and Marketing',
            type: 'collapse',
            url: 'Elysian/PricingAndMarketing',
            end: true,
            children: [
              {
                id: 'elysian-pam-Chanhassen',
                dense: true,
                title: 'Chanhassen',
                type: 'item',
                url: 'Elysian/PricingAndMarketing/Chanhassen',
                end: true,
              },
              {
                id: 'elysian-pam-DowntownPlaza',
                dense: true,
                title: 'Downtown Plaza',
                type: 'item',
                url: 'Elysian/PricingAndMarketing/DowntownPlaza',
                end: true,
              },
              {
                id: 'elysian-pam-Duluth',
                dense: true,
                title: 'Duluth',
                type: 'item',
                url: 'Elysian/PricingAndMarketing/Duluth',
                end: true,
              },
              {
                id: 'elysian-pam-LakeCity',
                dense: true,
                title: 'Lake City',
                type: 'item',
                url: 'Elysian/PricingAndMarketing/LakeCity',
                end: true,
              },
              {
                id: 'elysian-pam-Northfield',
                dense: true,
                title: 'Northfield',
                type: 'item',
                url: 'Elysian/PricingAndMarketing/Northfield',
                end: true,
              },
            ]
          }       
        ]
      },      
      {
        id: 'elysianSupport',
        title: 'Support',
        auth: ['elysian'],
        type: 'item',        
        url: 'support',
      }
    ]
  },

  // Free Routes
  /*
  {
    id: 'freeBrightSource', 
    title: 'Bright Sources (Free)',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    autoExpand: false,
    auth: ['admin', 'demo', 'free'],
    children: [
      {
        id: 'freeGroupRegulationV2',
        title: 'Regulations',        
        type: 'collapse',
        autoExpand: false,
        checkPermission: true,
        url: 'Free',
        auth: ['admin', 'demo', 'free'],
        children: [          
          {
            id: 'free-reg-skilled',
            dense: true,
            title: 'Skilled Nursing',
            type: 'item',
            url: 'Free/SNF',
            end: true,
          },
          {
            id: 'free-reg-al',
            title: 'Assisted Living',
            type: 'item',
            url: 'Free/AL',
            end: true,
          },          
        ]
      },
      {
        id: 'demoLumberjack',
        title: 'Lumberjack',        
        type: 'collapse',
        url: 'lumberjack/clinical',
        auth: ['admin', 'demo'],
        dense: true,
        children: [          
          {
            id: 'demoLumberjackClinical',
            title: 'Clinical',
            type: 'item',
            url: 'lumberjack/clinical',
            auth: ['admin', 'demo'],
            dense: true,
            end: true,
          },
          {
            id: 'demoLumberjackFinancial',
            title: 'Financial',
            auth: ['admin', 'demo'],
            type: 'item',
            url: 'lumberjack/financial',
            dense: true,
            end: true,
          },
        ]
      },
      {
        id: 'demoSupport',
        title: 'Support',
        auth: ['demo'],
        type: 'item',        
        url: 'support',
      }
    ],
  },
  */
  
  
  // Leading Age
  {
    id: 'leadingAge',
    title: 'Leading Age',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin', 'staff'],
    children: [
      {
        id: 'leadingAgelumberjack',
        title: 'Lumberjack',        
        type: 'collapse',
        url: 'lumberjack/clinical',
        auth: ['admin', 'staff'],
        dense: true,
        children: [
          {
            id: 'leadingAgelumberjackClinical',
            title: 'Clinical',
            type: 'item',
            url: 'lumberjack/clinical',
            auth: ['admin', 'staff'],
            dense: true,
            end: true,
          },
          {
            id: 'leadingAgelumberjackFinancial',
            title: 'Financial',
            auth: ['admin', 'staff'],
            type: 'item',
            url: 'lumberjack/financial',
            dense: true,
            end: true,
          },
        ]
      },
      {
        id: 'leadingAgeSupport',
        title: 'Support',
        auth: ['staff'],
        type: 'item',        
        url: 'support',
      }
    ],
  },

  
  {
    id: 'applicationsTools',
    title: 'DEMOS',    
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    checkPermission: true,
    auth: ['admin'],
    children: [
      {
        id: 'lumberjack',
        title: 'Lumberjack',        
        type: 'collapse',
        url: 'lumberjack/clinical',
        auth: 'admin',
        dense: true,
        children: [
          
          {
            id: 'lumberjackClinical',
            title: 'Clinical',
            type: 'item',
            url: 'lumberjack/clinical',
            auth: 'admin',
            dense: true,
            end: true,
          },
          {
            id: 'lumberjackFinancial',
            title: 'Financial',
            auth: 'admin',
            type: 'item',
            url: 'lumberjack/financial',
            dense: true,
            end: true,
          },
        ]
      },
      {
        id: 'systemTools',
        title: 'Tools',        
        type: 'collapse',
        auth: ['admin'],
        checkPermission: true,
        children: [          
          {
            id: 'toolImportCare',
            title: 'Business Card Import',
            type: 'item',
            url: 'import-card',
            auth: ['admin'],
            end: true,
          },
        ]
      },
      
      {
        id: 'lumberjackSupport',
        title: 'Support',
        auth: ['admin', 'customer-cornerstone'],
        type: 'item',        
        url: 'support',
      }
    ]
  },  
  
];

/* 
  {
    id: 'integration',
    title: 'Configuration',    
    auth: 'admin',
    active: 'square',
    type: 'group',
    icon: 'heroicons-outline:bolt',
    
    children: [
      {
        
        id: 'admin',
        title: 'Administration',     
        type: 'collapse',
              
        children: [
          {
            id: 'prompts',
            title: 'Prompts',     
            type: 'item',
            icon: 'heroicons-outline:chat',
            url: 'prompts',
          },
          {
            id: 'ai',
            title: 'AI',     
            type: 'item',
            icon: 'heroicons-outline:cloud',
            url: 'ai',
          },
          {
            id: 'datamodels',
            title: 'Data Models',     
            type: 'item',
            icon: 'heroicons-outline:table',
            url: 'datamodels',
          },
          {
            id: 'aikeys',
            title: 'Keys',     
            type: 'item',
            icon: 'heroicons-outline:key',
            url: 'keys',
          },
          {
            id: 'Permissions',
            title: 'Permissions',
            type: 'item',
            icon: 'heroicons-outline:lock-closed',
            //url: 'permissions/Members',
          }
        ]
      }
    ]
  }
  */
export default navigationConfig;
