import { config } from "dotenv"; 
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
import { getFirestore } from 'firebase/firestore'
// https://firebase.google.com/docs/web/modular-upgrade

config();

/*
console.log(`Environment: ${process.env.REACT_APP_ENV}`);
console.log(`Project: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`);
console.log(`Measurment: ${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`);
*/

// authDomain:         process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
// authDomain:         'http://localhost:3000/__/auth/handler',
// authDomain:         'https://lumberjack-396203.firebaseapp.com/__/auth/handler',
// WORKS: authDomain:         'lumberjack-396203.firebaseapp.com/__/auth/handler',
// https://https//lumberjack-396203.firebaseapp.com/__/auth/handler?apiKey=AIzaSyCSd-2TvMiamgW6aLHrRCGanO_VnSGMXus&appName=%5BDEFAULT%5D&authType=signInViaPopup&redirectUrl=http%3A%2F%2Flocalhost%3A3000%2FSign-in&v=10.13.0&eventId=2103635097&providerId=microsoft.com&scopes=mail.read%2Ccalendars.read
// https://stackoverflow.com/questions/44015751/firebase-js-api-auth-account-exists-with-different-credential


// authDomain:         'lumberjack-396203.firebaseapp.com',
// authDomain:         'axis-b4487.firebaseapp.com',
// authDomain:         'localhost:3000/__/auth/handler', 
// authDomain:         'axis-b4487.web.app', 
// authDomain:         'app.dovaxis.com/__/auth/handler' // https://firebase.google.com/docs/auth/web/redirect-best-practices?hl=en&authuser=0&_gl=1*1gjzisp*_ga*MTgyODE4NTEzMS4xNzMzMTYyMDgx*_ga_CW55HF8NVT*MTczNjg4NzU4MS44NS4xLjE3MzY4ODgyNTEuNTkuMC4w
const domainProduction = 'app.dovaxis.com';
const  domainLocal = 'localhost:3000';
const  domain = domainProduction;

let app = initializeApp({
    apiKey:             process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain:         domain,  
    projectId:          process.env.REACT_APP_FIREBASE_PROJECT_ID, 
    storageBucket:      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, 
    messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, 
    appId:              process.env.REACT_APP_FIREBASE_APP_ID, 
    measurementId:      process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

console.log(process.env.REACT_APP_FIREBASE_PROJECT_ID)

export const firebase = app;
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const db = getDatabase(app);
export const firestore = getFirestore(app);


export function loadFirebase() {
    return app;
}

export function getFirebaseAnalytics() {
    return analytics;
}


export default app;