import { lazy } from 'react';

import SignInPage from './SignInPage';
import MicrosoftSignInPage from './MicrosoftSignInPage2';

import authRoles from '../../../auth/authRoles';
// const SignInPage = lazy(() => import('./SignInPage'));

const SignInConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'sign-in',
      children: [     
        { path: '', element: <SignInPage /> },
        { path: 'Microsoft', element: <MicrosoftSignInPage /> },
      ]
    },
  ],
};

export default SignInConfig;
