import ElysianRoutes from "./ElysianRoutes";
import SholomRoutes from "./SholomRoutes";
import FreeRoutes from "./FreeRoutes"
import DemoRoutes from "./DemoRoutes"
import AhiRoutes from './AhiRoutes';
import TheGenevaSuitesRoutes from './TheGenevaSuitesRoutes';
import TrainingRoutes from './TrainingRoutes';

let routes = [...TrainingRoutes.routes, ...TheGenevaSuitesRoutes.routes, ...ElysianRoutes.routes, ...SholomRoutes.routes, ...FreeRoutes.routes, ...AhiRoutes.routes, ...DemoRoutes.routes]

let settings = {
  layout: {
    config: {},
  },
};

const ClientRoutes = {settings, routes}


export default ClientRoutes;