import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query'; // https://www.npmjs.com/package/json-to-graphql-query
import { config } from "dotenv";
config();

let svcURL = process.env.REACT_APP_DOVAXIS_API_PRIMARY;
// let svcURL = process.env.REACT_APP_DOVAXIS_API_SECONDARY;
// let svcURL = 'http://localhost:8080';

const requestHeaders = new Headers();
//requestHeaders.append("authorization", `Bearer ${process.env.HUBSPOT_API_KEY}`);
requestHeaders.append("content-type", "application/json");

/*

  User Activity API
  -------------------------------
  Call user activity API
  Note: MongoDB's TimeSeries Collection is configured as follows:
        Collection Type:  TimeSeries
        timeField:        timestamp
        metaField:        details
        granularity:      seconds

*/

async function saveUserActivity(data, isClosing=false) {

  let graphqlQuery = '';

  try {
    const mutation = {
      mutation:  {
        logActivity:  {
          __args: {
            input: {
                events: data.events,
                flushTime: data.flushTime
            }
        },
          count: true,
          error: true,
          message: true,
          success: true
        }
      }
    }

    graphqlQuery = jsonToGraphQLQuery(mutation, { pretty: false });
  }
  catch(ex){
      console.log(`Failed to create GraphQL Query in logActivityAPI. ${ex.message}`);
      return;
  }

  if(isClosing){
    try {
      const blob = new Blob([JSON.stringify({query: graphqlQuery})], requestHeaders);
      navigator.sendBeacon(`${svcURL}/BrightSource`, blob);
    }
    catch(ex){
      console.log('Failed to save logs while closing browser!')
    }
  }
  else {
    try {
      const response = await fetch(`${svcURL}/BrightSource`, {
          method: "POST",
          headers: requestHeaders,
          body: JSON.stringify({query: graphqlQuery})
        });

        let result = await response.json();
        if(result.errors){
            console.log('Errors occured in calling logActivity API');
            //console.log(result.errors[0].message);
            return flattenErrors(errors)
        }

        //console.log(result?.data);
        return result?.data;
    }
    catch(ex){
        console.log(`An error resturned from GraphQL Request in logActivityAPI. ${ex.message}`);
        return;
    }
  }
  
}

function flattenErrors(errors){
  let errorMessage = '';
  errors.forEach(err => {
    console.log(err.message);
    errorMessage += err.message + ' ';
  })
  return {error: { message: errorMessage }};
}

export default saveUserActivity;
