import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp')); 

const AhiRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [    
    {
      path: 'AHI',
      element: <Layout />,
      auth: ['admin', 'ahi'],
      children: [
        {
          path: '',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            pageTitle="AHI BrightSources"
            pageDescription="Your AHI AI Assistant"
          />,
        },
        {
          comment: {/* CDC */},
          path: 'CDC',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='CDC'
            pageTitle="AHI CDC Resources"
            pageDescription="Your AHI AI Assistant"
          />,
        },
        {
          comment: {/* Clinical Reference Material */},
          path: 'CRM',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='Clinical Reference Material'
            pageTitle="Clinical Reference Material"
            pageDescription="Your AHI CRM Assistant"
          />,
        },
        {
          comment: {/* Code of Federal Regulations (CFR) */},
          path: 'CFR',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='Code of Federal Regulations (CFR)'
            pageTitle="Code of Federal Regulations (CFR)"
            pageDescription="Your AHI CFR Assistant"
          />,
        },
        {
          comment: {/* Employment Documents */},
          path: 'Employment',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='Employment Documents'
            pageTitle="Employment Documents"
            pageDescription="Your AHI Employment Assistant"
          />,
        },
        {
          comment: {/* Instructions & Guides */},
          path: 'Instructions',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='Instructions & Guides'
            pageTitle="Instructions & Guides"
            pageDescription="Your AHI Instruction Assistant"
          />,
        },
        {
          comment: {/* MDS */},
          path: 'MDS',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='MDS'
            pageTitle="MDS Resources"
            pageDescription="AHI MDS AI Assistant"
          />
        },
        
        {
          comment: {/* Regulations */},
          path: 'Regs',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='State Regulations - SNF'
            pageTitle="AHI SNF Regulations"
            pageDescription="AHI SNF Regulations AI Assistant"
          />,
          children: [
            {
              comment: {/* State Regulations - SNF */},
              path: 'SNF',
              auth: ['admin', 'ahi'],
              element: <ChatApp 
                org={{key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-1-v2"
                level1='State Regulations - SNF'
                pageTitle="AHI SNF Regulations"
                pageDescription="SNF Regulations AI Assistant"
              />,
              children: [
                {
                  path: '',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - SNF'
                    pageTitle="SNF Regulations"
                    pageDescription="SNF Regulations AI Assistant"
                  />
                },
                {
                  path: 'AZ',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - SNF'
                    level2='AZ'               
                    pageTitle="SNF Regulations - AZ"
                    pageDescription="Arizona SNF Regulations AI Assistant"
                  />
                },
                {
                  path: 'CO',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - SNF'
                    level2='CO'
                    pageTitle="SNF Regulations - CO"
                    pageDescription="Colorado SNF Regulations AI Assistant"
                  />
                },
                {
                  path: 'MN',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - SNF'
                    level2='MN'
                    pageTitle="SNF Regulations - MN"
                    pageDescription="Minnesota SNF Regulations AI Assistant"
                  />
                },
                {
                  path: 'TN',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - SNF'
                    level2='TN'
                    pageTitle="SNF Regulations - TN"
                    pageDescription="Tennessee SNF Regulations AI Assistant"
                  />
                },
                {
                  path: 'WI',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - SNF'
                    level2='WI'
                    pageTitle="SNF Regulations - WI"
                    pageDescription="Wisconsin SNF Regulations AI Assistant"
                  />
                }
              ]
            },

            {
              comment: {/* State Regulations - AL */},
              path: 'AL',
              auth: ['admin', 'ahi'],
              element: <ChatApp 
                org={{key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-1-v2"
                level1='State Regulations - AL'
                pageTitle="AHI AL Regulations"
                pageDescription="AHI AL Regulations AI Assistant"
              />,
              children: [
                {
                  path: '',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - AL'
                    pageTitle="AHI AL Regulations"
                    pageDescription="AHI AL Regulations AI Assistant"
                  />
                },
                {
                  path: 'AZ',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - AL'
                    level2='AZ'
                    pageTitle="Arizona AL Regulations"
                    pageDescription="Arizona State Regulations AI Assistant"
                  />
                },
                {
                  path: 'CO',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - AL'
                    level2='CO'
                    pageTitle="Colorado AL Regulations"
                    pageDescription="Colorado Regulations AI Assistant"
                  />
                },
                {
                  path: 'FL',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - AL'
                    level2='FL'
                    pageTitle="Florida AL Regulations"
                    pageDescription="Florida Regulations AI Assistant"
                  />
                },
                {
                  path: 'MN',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - AL'
                    level2='MN'
                    pageTitle="Minnesota AL Regulations"
                    pageDescription="Minnesota Regulations AI Assistant"
                  />
                },
                {
                  path: 'TN',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - AL'
                    level2='TN'
                    pageTitle="Tennessee AL Regulations"
                    pageDescription="Tennessee Regulations AI Assistant"
                  />
                },
                {
                  path: 'WI',
                  auth: ['admin', 'ahi'],
                  element: <ChatApp 
                    org={{key: "AHI", name: "Advanced Health Institute"}}
                    collection="AHI-1-v2"
                    level1='State Regulations - AL'
                    level2='WI'
                    pageTitle="Wisconsin AL Regulations"
                    pageDescription="Wisconsin Regulations AI Assistant"
                  />
                },
              ]
            },
          ]
        },

        {
          comment: {/* Policies and Procedures */},
          path: 'Policies',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='Policies and Procedures'
            pageTitle="Policies and Procedures"
            pageDescription="AHI BrightSource AI Assistant"
          />
        },
        {
          path: 'Tools',
          auth: ['admin', 'ahi'],
          element: <ChatApp 
            org={{key: "AHI", name: "Advanced Health Institute"}}
            collection="AHI-1-v2"
            level1='Tools & Templates'
            pageTitle="Tools & Templates"
            pageDescription="AHI BrightSource AI Assistant"
          />
        }

        
        
      ]
    },    
    {
      path: 'AHI-OnCall',
      element: <Layout />,
      auth: ['admin', 'ahi_oncall'],
      children: [
        {
          path: '',
          auth: ['admin', 'ahi_oncall'],
          children: [
            {
              path: '',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                pageTitle="AHI OnCall"
                pageDescription="Please select a client in the left navigation to begin."
              />,
            },
            {
              path: 'Aviva',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Aviva"
                pageTitle="AHI OnCall"
                pageDescription="Aviva Resources"
              />,
            },
            {
              path: 'DiamondWillow',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Diamond Willow"
                pageTitle="AHI OnCall"
                pageDescription="Diamond Willow Resources"
              />,
            },

            {
              path: 'EpiscopalHomes',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Episcopal Homes"
                pageTitle="AHI OnCall"
                pageDescription="Episcopal Homes Resources"
              />,
            },
            {
              path: 'GiannaHomes',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Gianna Homes"
                pageTitle="AHI OnCall"
                pageDescription="Gianna Homes Resources"
              />,
            },
            {
              path: 'Greenbrier',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Greenbrier"
                pageTitle="AHI OnCall"
                pageDescription="Greenbrier Resources"
              />,
            },
            {
              path: 'PeacefulLiving',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Peaceful Living"
                pageTitle="AHI OnCall"
                pageDescription="Peaceful Living Resources"
              />,
            },
            {
              path: 'PrairieSeniorCottages',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Prairie Senior Cottages"
                pageTitle="AHI OnCall"
                pageDescription="Prairie Senior Cottages"
              />,
            },
            {
              path: 'SaintTherese',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Saint Therese"
                pageTitle="AHI OnCall"
                pageDescription="Saint Therese Resources"
              />,
            },

            {
              path: 'SholomCommunityAlliance',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="Sholom"
                pageTitle="AHI OnCall"
                pageDescription="Sholom Community Alliance"
              />,
            },
            {
              path: 'TheGenevaSuites',
              auth: ['admin', 'ahi_oncall'],
              element: <ChatApp 
                org={{id: "VvBpXK8lAnRjj4ro2cie", key: "AHI", name: "Advanced Health Institute"}}
                collection="AHI-OnCall-v2"
                level1="The Geneva Suites"
                pageTitle="AHI OnCall"
                pageDescription="The Geneva Suites"
              />,
            },
          ]
        },
      ]
    }
  ]
}; 

export default AhiRoutes;

